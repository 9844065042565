.uppy-Webcam-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.uppy-Webcam-videoContainer {
  position: relative;
  flex: 1;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  text-align: center;
  background-color: #333;
}

.uppy-Webcam-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.uppy-Webcam-video--mirrored {
  transform: scaleX(-1);
}

.uppy-Webcam-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 75px;
  padding: 20px 20px;
}

.uppy-Webcam-videoSourceContainer {
  flex-grow: 0;
  width: 100%;
}

.uppy-size--lg .uppy-Webcam-videoSourceContainer {
  width: 33%;
  margin: 0;
}

.uppy-Webcam-videoSource-select {
  display: block;
  width: 100%;
  max-width: 90%;
  margin: auto;
  margin-bottom: 10px;
  padding: 0.4em 1em 0.3em 0.4em;
  font-size: 16px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23757575%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.4em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  border: 1px solid #757575;
}
.uppy-size--lg .uppy-Webcam-videoSource-select {
  margin-bottom: 0;
  font-size: 14px;
}

.uppy-Webcam-videoSource-select::-ms-expand {
  display: none;
}

.uppy-Webcam-buttonContainer {
  width: 50%;
  margin-left: 25%;
  text-align: center;
}

.uppy-size--lg .uppy-Webcam-buttonContainer {
  width: 34%;
  margin-left: 0;
}

.uppy-Webcam-recordingLength {
  flex-grow: 0;
  width: 25%;
  color: #757575;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  text-align: right;
}

.uppy-size--lg .uppy-Webcam-recordingLength {
  width: 33%;
}

.uppy-Webcam-button {
  width: 45px;
  height: 45px;
  color: #fff;
  background-color: #e32437;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.uppy-Webcam-button:focus {
  outline: none;
}
.uppy-Webcam-button::-moz-focus-inner {
  border: 0;
}
.uppy-Webcam-button:focus {
  box-shadow: 0 0 0 3px rgba(18, 105, 207, 0.5);
}
.uppy-Webcam-button:hover {
  background-color: #d31b2d;
}
[data-uppy-theme=dark] .uppy-Webcam-button:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-Webcam-button::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-Webcam-button:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}

.uppy-Webcam-button--submit {
  margin: 0 12px;
  background-color: #1269cf;
}
.uppy-Webcam-button--submit:hover {
  background-color: #105db8;
}

.uppy-Webcam-button svg {
  display: inline-block;
  width: 30px;
  max-width: 100%;
  height: 30px;
  max-height: 100%;
  overflow: hidden;
  vertical-align: text-top;
  fill: currentColor;
}

.uppy-size--md .uppy-Webcam-button {
  width: 60px;
  height: 60px;
}

.uppy-Webcam-button--picture {
  margin-right: 12px;
}

.uppy-Webcam-permissons {
  display: flex;
  flex: 1;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 15px;
}

.uppy-Webcam-permissons p {
  max-width: 450px;
  margin: 0;
  color: #939393;
  line-height: 1.3;
  line-height: 1.45;
  text-align: center;
}

.uppy-Webcam-permissonsIcon svg {
  width: 100px;
  height: 75px;
  margin-bottom: 30px;
  color: #bbb;
}

.uppy-Webcam-title {
  max-width: 500px;
  margin: 0;
  margin-bottom: 5px;
  padding: 0 15px;
  color: #333;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.35;
  text-align: center;
}
[data-uppy-theme=dark] .uppy-Webcam-title {
  color: #eaeaea;
}